import React from "react"
import PropTypes from "prop-types"
import Layout from "components/molecules/Layout"
import SEO from "components/molecules/SEO"

import ContactModal from "components/content/ContactModal"

const ContactPage = ({ pageContext: { language } }) => {
  return (
    <Layout language={language}>
      <SEO title="Contact" />
      <ContactModal asPage />
    </Layout>
  )
}

ContactPage.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
}

export default ContactPage
